<template>
  <el-form size="mini" ref="form" :model="role" :rules="formRules" label-width="120px" status-icon>
    <el-form-item label="名称" prop="name">
      <el-input v-model="role.name" placeholder="请输入角色名称"></el-input>
    </el-form-item>
    <el-form-item label="描述" prop="desc">
      <el-input v-model="role.desc" placeholder="请输入角色描述" type="textarea" :rows="2"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button v-if="id" type="primary" @click="handleSubmit(true)" v-waves>保存</el-button>
      <el-button v-if="!id" type="primary" @click="handleSubmit(true)" v-waves>保存并继续</el-button>
      <el-button v-if="!id" type="primary" @click="handleSubmit(false)" v-waves>保存并退出</el-button>
      <el-button v-waves @click="handleCancel">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<style scoped>
  .el-form {
    margin: 20px;
  }

</style>

<script>
import adminRoleAddApi from '@/apis/role/roleAdd'
import adminRoleInfoApi from '@/apis/role/roleInfo'
import commonApi from '@/apis/common'
import waves from '@/directive/waves' // 水波纹指令
export default {
  directives: {
    waves
  },
  props:["id"],
  data () {
    return {
      role: {
        id: null,
        name: '',
        desc: '',
        dept:{id:"870742234758238208"}
      },
      props: {
        value: 'id',
        label: 'name',
        children: 'children',
        checkStrictly:true
      },
      formRules: {
        name: [{
          required: true,
          message: '请输入角色名称'
        }, {
          max: 20,
          message: '角色名称长度需少于 20 个字符'
        }, {
          validator: (rule, value, callback) => {
            commonApi.verifyRoleName(value, this.id).then(res => {
              if (!res.data) {
                callback(new Error('角色名称：' + value + '已存在'))
              } else {
                callback()
              }
            })
          },
          trigger: 'blur'
        }]
      }
    }
  },
  watch: {
    roleId () {
      this.resetForm()
    }
  },
  components: {},
  methods: {
    /**
       * 提交数据
       * @param {*} goon 新增后是否继续新增
       */
    handleSubmit (goon) {

      this.$refs.form.validate().then(response => {
        if (!response) {
          return
        }
        if (!this.id) {
          adminRoleAddApi.insert(this.role).then(response => {
            this.$message({
              title: '成功',
              message: '角色创建成功',
              type: 'success'
            })
            if (goon) {
              this.resetForm()
            } else {
              this.$router.replaceBy(this.$router.routesConfig.roleList)
            }
          })
        } else {
          adminRoleInfoApi.update(this.role).then(response => {
            this.$message({
              title: '成功',
              message: '角色更新成功',
              type: 'success'
            })
            this.$router.replaceBy(this.$router.routesConfig.roleList)
          })
        }
      })
    },
    /**
       * 取消按钮处理
       */
    handleCancel () {
      this.$router.replaceBy(this.$router.routesConfig.roleList)
    },
    /**
       * 获取角色信息
       */
    getRole () {
      if (this.id) {
        adminRoleInfoApi.getById(this.id).then(response => {
          this.role = response.data
        })
      }
    },
    /**
       * 重置表单
       */
    resetForm () {
      this.$refs.form.resetFields()
    }
  },
  created () {
    this.getRole()
  }
}

</script>
